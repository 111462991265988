<template>
  <v-dialog
    v-model="isAlert"
    :width="!$vuetify.breakpoint.smAndUp ? '80%' : '40%'"
    persistent
    content-class="alert"
  >
    <div class="alert-inner">
      <!-- 제목/내용 -->
      <div class="alert-content">
        <h4 v-html="alertTitle"></h4>
        <p v-html="alertContent"></p>
      </div>

      <!-- 버튼 -->
      <div class="alert-btns">
        <!-- 취소 -->
        <v-btn
          v-if="alertType === 'WITH_CONFIRM'"
          color="black"
          text
          plain
          :ripple="false"
          height="14"
          @click="clickCancel()"
        >
          {{ cancelText }}
        </v-btn>

        <!-- 확인 -->
        <v-btn
          color="edu"
          text
          plain
          :ripple="false"
          height="14"
          @click="clickConfirm()"
        >
          {{ confirmText }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      isAlert: state => state.alert.isAlert,
      alertType: state => state.alert.alertType,
      alertTitle: state => state.alert.alertTitle,
      alertContent: state => state.alert.alertContent,
      cancelText: state => state.alert.cancelText,
      confirmText: state => state.alert.confirmText,
      confirmFunc: state => state.alert.confirmFunc
    })
  },
  methods: {
    ...mapActions({
      runCloseAlert: 'alert/closeAlert'
    }),
    clickCancel() {
      this.runCloseAlert()
    },
    clickConfirm() {
      if (this.confirmFunc) {
        this.confirmFunc()
      }

      this.runCloseAlert()
    }
  }
}
</script>

<style lang="scss">
.alert {
  background-color: $color-white;
  border-radius: 10px !important;

  .alert-inner {
    padding: 24px;

    // 제목/내용
    .alert-content {
      margin-bottom: 16px;

      h4 {
        margin-bottom: 4px;
      }

      p {
        font-size: 15px;
        font-weight: bold;
      }
    }

    // 버튼
    .alert-btns {
      text-align: right;

      .v-btn {
        font-weight: bold !important;
        padding: 0 8px;
        min-width: 45px !important;
      }
    }
  }
}
</style>
